.app-body {
  /* max-width: 75vw; */
  /* max-height: 100vh; */
  margin: auto;
  text-align: center;
  position: relative;
  padding-bottom: 60px;
  min-height: 100vh;
}

.wrapper {
  height:100vh;
  /* max-width: 100vw; */
  position:relative;
  text-align: center;
}

.carousel-wrapper {
  margin-bottom: 2%;
  background-color: white !important;
  max-height: 10%;
}
@media screen and (max-width: 578px) {
  .carousel-wrapper {
    padding-top: 20px;
  }
}
@media screen and (max-width: 892px) {
  .carousel-wrapper {
    padding-top: 50px;
  }
}

.carousel .slide {
  background-color: white !important;
}

.carousel-pics {
  max-width: 80%;
  margin: auto;
}

/*  Navbar Styling   */
.row {
  width: 67%;
  max-width: 100vw;
  min-height: 6vh;
  margin: auto !important;
  padding: 0;
  border-bottom: 1.4px solid rgba(105, 105, 105, 0.5);
  /* box-shadow: 0px 3px 0.5px 0px rgba(0,0,0,0.1); */
}
@media screen and (max-width: 892px) {
  .row {
    width: 82%;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap');

.col-sm-2 {
  padding-top: 2vh;
  color: rgb(53, 53, 53);
  font-family: 'Playfair Display', serif;
  font-size: 0.8vw;
}
@media screen and (max-width: 578px) {
  .col-sm-2 {
    font-size: 4.5vw !important;
    padding: 1vh;
  }
}

@media screen and (max-width: 892px) {
  .col-sm-2 {
    font-size: 2vw;
  }
}
@media screen and (max-width: 1282px) {
  .col-sm-2 {
    font-size: 1.5vw;
  }
}

.col-sm-2:hover {
  background-color: rgb(53, 53, 53);
  color: white;
  text-decoration: none;
}

.col-sm-2:focus {
  background-color: rgb(53, 53, 53);
  color: white;
  text-decoration: none;
}

#logo {
  padding-top: 1.7vh;
  margin-left: 8.3333333%;
}
@media screen and (max-width: 578px) {
  #logo {
    margin-left: 0;
  }
}

#rc {
  width: 2.3vw;
}
@media screen and (max-width: 578px) {
  #rc {
    width: 10vw !important;
    margin-left: 0 !important;
  }
}
@media screen and (max-width: 892px) {
  #rc {
    width: 3.7vw;
  }
}
@media screen and (max-width: 1282px) {
  #rc {
    width: 3.7vw;
  }
}

/* Page styling  */

.body {
  text-align: center;
  min-width: 60%;
  margin: auto;
  text-align: justify;
  min-height: 100%;
  max-width: 55%;
  margin-top: 2vh;
}
@media screen and (max-width: 578px) {
  .body {
    text-align: left;
 }
}

img.header-photo {
  width: 100% !important;
  margin: auto;
  max-height: "60vh";
  overflow: "hidden";
}

h1, h2 {
   text-align: center;
   padding-bottom: 1%;
   padding-top: 3%;
   font-family: 'Playfair Display', serif;
}
@media screen and (max-width: 578px) {
  h1 {
    font-size: 7.8vw !important;
    padding-top: 25px !important;
 }
}

@media screen and (max-width: 578px) {
  h2 {
    font-size: 4.3vw !important;
 }
}

h5 {
  margin-top: 5% !important;
}

@media screen and (max-width: 578px) {
  h6 {
    font-size: 3.7vw !important;
    margin-top: 25px !important;
 }
}

.tab-header {
  margin-bottom: 3%;
}

#rich-pic {
  width: 100%;
  text-align: center;
}

#rich-pic img {
  margin-bottom: 2%;
  margin-top: 1%;
  min-width: 50%;
}
@media screen and (max-width: 578px) {
  #rich-pic img {
    height: 300px !important;
    margin: 0 0 18px 0;
 }
}
@media screen and (max-width: 892px) {
  #rich-pic img {
    height: 550px;
    margin: 0 0 18px 0;
 }
}

p {
  margin-top: 2% !important;
}

@media screen and (max-width: 578px) {
  p {
    font-size: 3.5vw;
 }
}

@media screen and (max-width: 578px) {
  ol {
    font-size: 3.5vw;
 }
}

.before-footer {
  margin-bottom: 5vh;
}
@media screen and (max-width: 892px) {
  h6.before-footer {
    margin-top: 7vh;
 }
}

/* Footer styling  */

.footer {
  width: 100%;
  max-width: 100vw;
  height: 60px;
  background-color: rgb(53, 53, 53);
  position: absolute;
  bottom: 0;
  left: 0;
}
@media screen and (max-width: 578px) {
  .footer {
    font-size: 2.3vw !important;
 }
}

.designer {
  padding: 20px 20px 0px 20px;
  margin: 0 !important;
  float: left;
  color: white;
  font-weight: 100;
}

.Copyright {
  /* font-family: 'Playfair Display', serif; */
  font-weight: 100;
  color: white;
  padding-right: 20px;
  padding-top: 20px;
  margin: 0 !important;
  float: right;
}